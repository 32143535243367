import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {
  DataGridPro,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useCreateEnvironmentMutation } from "../../backend/hooks/interactive/mutationCreateEnvironment";
import FileIcon from "../abstractComponents/fileIcon";
import getTreeHierarchyFromRepoObjectList from "../../helpers/treeHelper";
import { useGetHistoricRepoObjectsQuery } from "../../backend/hooks/history/queryGetHistoricRepoObjectsTreeView";

import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useListImagesQuery } from "../../backend/hooks/interactive/queryListImages";
import { useListInstanceTypesQuery } from "../../backend/hooks/interactive/queryListInstanceTypes";
import {
  Image,
  InstanceType,
  InteractiveFileInput
} from "../../backend/interactiveTypes";
import { PmxActivityDetailsContext } from "../../contexts/pmxActivityDetailsProvider";
import {
  formatStringToDateTime,
  priceCalculationPerHour
} from "../../helpers/stringHelper";
import Error from "../abstractComponents/error";
import Success from "../abstractComponents/success";
import UserManualLink from "../abstractComponents/usermanual";
import { InteractivePartialImport } from "./interactivePartialImport";
import { useGetHistoryLabels } from "../../backend/hooks/history/queryGetHistoryLabels";
import { HistoryLabel } from "../../backend/types";

export function CreateInteractiveModal(props: {
  repos: any;
  tree: any;
  disabeld?: boolean;
  actionId?: String;
}) {
  const { activityId, errorActivity, activityData } = useContext(
    PmxActivityDetailsContext
  );
  const [currentSubArray, setCurrentSubarray] = useState<any[]>([]);
  const [currentActionId, setCurrentActionId] = useState(props.actionId);
  const [tree, setTree] = useState(props.tree);

  const { historyLabels, getHistoryLabelsLoading, getHistoryLabelsError } =
    useGetHistoryLabels(Number(activityId));

  const import_columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Path",
      flex: 4,
      renderCell: (params: any) => (
        <>
          <FileIcon
            fileName={params.row.name}
            isValid={params.row.isValid}
            fontSize={"large"}
          />
          {params.row.name}
        </>
      )
    },
    {
      field: "createdAt",
      flex: 1,
      headerName: "Created At",
      valueGetter: (params: GridValueGetterParams) =>
        formatStringToDateTime(params.row.createdAt)
    },
    {
      field: "createdBy",
      flex: 1,
      headerName: "Created By",
      renderCell: (params: any) => params.row.createdBy
    }
  ];

  const [isCreateWindowOpen, setIsCreateWindowOpen] = useState(false);
  const [interactiveName, setInteractiveName] = useState("");
  const [newImageId, setNewImageId] = useState("");
  const [newSizeId, setNewSizeId] = useState("");
  const [stateActivityData, setStateActivityData] = useState<any>(null);

  const [isImportAllFiles, setIsImportAllFiles] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFileImport, setSelectedFileImport] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);

  const {
    environmentTypes,
    isLoadingEnvironmentTypes,
    errorListEnvironmentTypes
  } = useListInstanceTypesQuery();

  const {
    createEnvironment,
    isLoadingEnvironmentCreated,
    errorEnvironmentCreated,
    environmentCreated
  } = useCreateEnvironmentMutation();

  const { images, isLoadingListImages, errorListImages } =
    useListImagesQuery(10);

  const saveNewInteractive = () => {
    createEnvironment({
      variables: {
        activityId: activityId,
        imageId: newImageId,
        instanceTypeId: newSizeId,
        environmentName: interactiveName,
        importPaths: isImportAllFiles ? [] : decorateFiles(),
        actionId: currentActionId
      }
    }).then(() => {
      setIsCreateWindowOpen(false);
      setNewImageId("");
      setNewSizeId("");
      setInteractiveName("");
      setActiveStep(0);
      setSelectedFileImport([]);
      setIsImportAllFiles(true);

      if (props.actionId) {
        window.location.href = `/activity/${activityId}/interactive`;
      }
    });
  };

  // modal steps
  const steps = [
    "Interactive Environment Settings",
    "Choose files to import",
    "Summary"
  ];

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleNext = () => {
    if (isImportAllFiles) {
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (isImportAllFiles) {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const setSelectedImport = () => {
    setSelectedFileImport([]);
    setIsImportAllFiles((prevState) => !prevState);
  };

  const getFileColumns = () => {
    let rows: any = [];
    selectedFileImport.forEach((x) => {
      const finding = currentSubArray.find((y) => y.name === x);
      if (finding) {
        rows.push(finding);
      }
    });

    return rows;
  };

  useEffect(() => {
    const currentArray: any[] = [];
    props.repos.map((repo: any) =>
      repo.repoObjects.map((file: any) =>
        currentArray.push({
          repoName: repo.repo.name,
          name: `${repo.repo.name}/${file.name}`,
          path: file.name,
          isDir: file.isDir,
          createdAt: file.created,
          createdBy: file.createdBy,
          isValid: file.isValid,
          versionId: file.versionId
        })
      )
    );

    setCurrentSubarray(currentArray);
  }, [props.repos]);

  useEffect(() => {
    if (isImportAllFiles) {
      const rows: any = [];
      currentSubArray.map((file) => rows.push(file.name));

      setSelectedFileImport(rows);
    }
  }, [isImportAllFiles, currentSubArray]);

  const findInstance = (newSizeId: string) => {
    return environmentTypes?.find((s: any) => s.instanceTypeId === newSizeId);
  };

  const decorateFiles = () => {
    const decoratedFiles: InteractiveFileInput[] = [];
    currentSubArray?.forEach((x) => {
      if (
        selectedFileImport.length > 0 &&
        selectedFileImport.includes(x.name)
      ) {
        decoratedFiles.push({
          repoName: x.repoName,
          path: x.path
        });
      }
    });

    return decoratedFiles.length > 0 ? decoratedFiles : null;
  };

  const validateSelectedFiles = () => {
    if (selectedFileImport.length > 0) {
      return getFileColumns();
    } else if (selectedFileImport.length === 0) {
      return [];
    } else {
      return currentSubArray;
    }
  };

  const sortInstanceTypes = () => {
    return environmentTypes
      ?.slice()
      .sort((a: InstanceType, b: InstanceType) => {
        if (a.cpu === b.cpu) {
          return a.memory - b.memory;
        }
        return a.cpu - b.cpu;
      });
  };

  /** historical tree view gets pulled if needed **/
  const {
    files,
    isLoading: isLoadingFiles,
    error: errorFiles
  } = useGetHistoricRepoObjectsQuery(stateActivityData, {
    actionId: currentActionId
  });

  useEffect(() => {
    if (currentActionId && files) {
      setTree(getTreeHierarchyFromRepoObjectList(files));
    }
  }, [files]);

  useEffect(() => {
    if (currentActionId) {
      setStateActivityData(activityData);
    } else {
      setStateActivityData(null);
      setTree(props.tree);
    }
  }, [currentActionId]);
  /** end historical  **/

  return (
    <>
      <Button
        onClick={() => setIsCreateWindowOpen(true)}
        id="create_new_interactive"
        className="float-end button-secondary"
        variant={props.actionId ? "outline-primary" : "primary"}
        disabled={props.disabeld}
      >
        <CloudDoneOutlinedIcon />
        Create Interactive Env
      </Button>

      <Modal
        show={isCreateWindowOpen}
        onHide={() => {
          setIsCreateWindowOpen(false);
          setActiveStep(0);
        }}
        dialogClassName="modal-75w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Create New Interactive
            {props.actionId && " Historical for actionId: " + props.actionId}
          </Modal.Title>
          <UserManualLink
            url="/usermanual/interactive/"
            testId="um-interactive-create"
            className="align-self-start ms-1"
          />
        </Modal.Header>
        <Modal.Body>
          {(isLoadingEnvironmentCreated ||
            isLoadingListImages ||
            isLoadingEnvironmentTypes) && (
            <div className="center_div">
              <Spinner animation="border" className="spinner_color" />
              <p>Loading...</p>
            </div>
          )}
          <Stepper activeStep={activeStep} className="mb-4">
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 0 && (
            <>
              <Form.Group
                className="mb-3"
                key="form_interactive_name"
                controlId="interactive_name"
              >
                <Form.Label>
                  Interactive Name <span className="red_form">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  required
                  placeholder="Interactive Name"
                  value={interactiveName}
                  onChange={(e) => setInteractiveName(e.target.value)}
                />
              </Form.Group>
              <br />
              <FormControl fullWidth id="interactive-modal-form-image-type">
                <InputLabel id="interactive-modal-input-image-type">
                  Image
                </InputLabel>

                <Select
                  labelId="interactive-modal-input-image-type"
                  id="interactive-modal-select-image-type"
                  data-testid="image-type"
                  value={newImageId}
                  label="Image"
                  onChange={(event) => {
                    setNewImageId(event.target.value);
                  }}
                >
                  {images
                    ?.slice()
                    .sort((a: Image, b: Image) => {
                      const aDate = new Date(a.createdAt);
                      const bDate = new Date(b.createdAt);

                      return bDate.getTime() - aDate.getTime();
                    })
                    .map((envType: Image) => (
                      <MenuItem key={envType.imageId} value={envType.imageId}>
                        <Row id={`row-image-${envType.imageId}`}>
                          <Typography
                            sx={{ width: "200px" }}
                            variant="button"
                            display="block"
                          >
                            {envType.imageName}
                          </Typography>
                          <div className="d-flex gap-4 fs-12px">
                            <p className="mb-0">
                              Platform:
                              {envType.platformDetails.charAt(0).toUpperCase() +
                                envType.platformDetails.slice(1)}
                            </p>
                            <p className="mb-0">
                              Created at: {envType.createdAt}
                            </p>
                            <p className="mb-0">
                              Architecture: {envType.architecture}
                            </p>
                          </div>
                        </Row>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <br />
              <br />
              <FormControl fullWidth id="interactive-modal-form-instance-type">
                <InputLabel id="interactive-modal-input-instance-type">
                  Instance Type
                </InputLabel>

                <Select
                  labelId="interactive-modal-input-instance-type"
                  id="interactive-modal-select-instance-type"
                  data-testid="instance-type"
                  value={newSizeId}
                  label="Select a Size"
                  onChange={(event) => {
                    setNewSizeId(event.target.value);
                  }}
                >
                  {sortInstanceTypes().map((envType: InstanceType) => (
                    <MenuItem
                      key={envType.instanceTypeId}
                      value={envType.instanceTypeId}
                      id={`instance-type-${envType.instanceTypeId}`}
                    >
                      <Row id={`row-instance-type-${envType.instanceTypeId}`}>
                        <Typography
                          sx={{ width: "200px", fontWeight: "bold" }}
                          variant="button"
                          display="block"
                        >
                          {envType.instanceTypeId}
                        </Typography>
                        <Typography sx={{ width: "140px" }}>
                          {envType.cpu}xCPU
                        </Typography>
                        <Typography sx={{ width: "150px" }}>
                          {envType.memory}GB RAM
                        </Typography>
                        <Typography sx={{ width: "140px" }}>
                          €
                          {Math.round(
                            priceCalculationPerHour(envType.instanceTypeId) * 24
                          )}
                          /Day
                        </Typography>
                      </Row>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth id="interactive-modal-form-history-label">
                <InputLabel
                  shrink={true}
                  id="interactive-modal-input-history-label"
                >
                  History Label
                </InputLabel>
                <Select
                  labelId="interactive-modal-input-history-label"
                  id="interactive-modal-select-history-label"
                  data-testid="history-label"
                  value={currentActionId ? currentActionId : "null"}
                  label="Select a History Label"
                  disabled={getHistoryLabelsLoading || Boolean(props.actionId)}
                  onChange={(event) => {
                    if (event.target.value && event.target.value != "null") {
                      setCurrentActionId(event.target.value);
                    } else {
                      setCurrentActionId(undefined);
                    }
                  }}
                  displayEmpty
                >
                  <MenuItem value={"null"} key={"null"}>
                    None
                  </MenuItem>
                  {historyLabels?.historyLabels.map(
                    (historyLabel: HistoryLabel) => (
                      <MenuItem
                        value={historyLabel.actionId}
                        key={historyLabel.actionId}
                      >
                        {historyLabel.name}
                      </MenuItem>
                    )
                  )}
                  {props.actionId &&
                    !historyLabels?.historyLabels.find(
                      (historyLabel: HistoryLabel) =>
                        historyLabel.actionId === props.actionId
                    ) && (
                      <MenuItem value={props.actionId} key={props.actionId}>
                        Action ID {props.actionId}
                      </MenuItem>
                    )}
                </Select>
                <div className="mx-2">
                  <FormControlLabel
                    control={<Checkbox checked={isImportAllFiles} />}
                    onChange={setSelectedImport}
                    label="Import All Files"
                  />
                </div>
              </FormControl>
            </>
          )}
          {activeStep === 1 && (
            <>
              {isLoadingFiles ? (
                <div className="center_div">
                  <Spinner animation="border" className="spinner_color" />
                  <p>Loading...</p>
                </div>
              ) : (
                <InteractivePartialImport
                  selectedFileImport={selectedFileImport}
                  setSelectedFileImport={setSelectedFileImport}
                  tree={props.tree}
                />
              )}
            </>
          )}

          {activeStep === 2 && (
            <Form.Group>
              <Form.Label className="mt-2">Interactive Name</Form.Label>
              <Form.Control type="text" value={interactiveName} disabled />
              <Form.Label className="mt-2">Image</Form.Label>
              <Form.Control
                type="text"
                value={
                  images?.find((i: any) => i.imageId === newImageId)?.imageName
                }
                disabled
              />
              <Form.Label className="mt-2">Instance Type</Form.Label>
              <br />
              <div className="d-flex gap-4">
                {findInstance(newSizeId)?.instanceTypeId}
                <div>{findInstance(newSizeId)?.cpu}xCPU</div>
                <div> {findInstance(newSizeId)?.memory}GB RAM</div>
                <div>
                  €
                  {Math.round(
                    priceCalculationPerHour(
                      findInstance(newSizeId)?.instanceTypeId
                    ) * 24
                  )}
                  /Day
                </div>
              </div>
              <FormControlLabel
                control={<Checkbox checked={isImportAllFiles} />}
                disabled
                label="Import All Files"
              />
              <br />
              <DataGridPro
                componentsProps={{ panel: { disablePortal: true } }}
                rows={validateSelectedFiles()}
                columns={import_columns}
                getRowId={(row) => row.name}
                rowHeight={50}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "name", sort: "asc" }]
                  }
                }}
                pagination
                pageSize={pageSize}
                page={page}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(pageSize) => setPageSize(pageSize)}
                rowsPerPageOptions={[50, 100, 500, 1000]}
                className="interactive-file-summary-table"
              />
            </Form.Group>
          )}
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="me-auto"
            variant="secondary"
            onClick={() => {
              setIsCreateWindowOpen(false);
              setActiveStep(0);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            Back
          </Button>
          <Button
            variant="primary"
            disabled={
              isLoadingEnvironmentCreated ||
              interactiveName === "" ||
              newImageId === "" ||
              newSizeId === ""
            }
            onClick={activeStep === 2 ? saveNewInteractive : handleNext}
          >
            {activeStep === 2 ? "Create New" : "Next"}
          </Button>
        </Modal.Footer>
      </Modal>

      {(errorListEnvironmentTypes ||
        errorActivity ||
        errorListImages ||
        getHistoryLabelsError ||
        errorFiles ||
        errorEnvironmentCreated) && (
        <Error
          error={
            errorListEnvironmentTypes ||
            errorActivity ||
            errorListImages ||
            getHistoryLabelsError ||
            errorFiles ||
            errorEnvironmentCreated
          }
        />
      )}

      {environmentCreated && (
        <Success message="The initialization will take some minutes, please wait until state is running and connect button is visible and active." />
      )}
    </>
  );
}
